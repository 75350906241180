// src/pages/Dashboard.js
import React from 'react';
import Sidebar from '../components/sidebar'; // Ensure correct casing for Sidebar
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
  const navigate = useNavigate();

  // Check if the token exists in session storage
  const token = localStorage.getItem('token');

  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove token from session storage
    navigate('/login'); // Redirect to login page
  };

  // If the token does not exist, return "Not logged in"
  if (!token) {
    return <div className="text-center mt-5">Not logged in</div>;
  }

  return (
    <main className="App">
      <div className="d-flex">
        <Sidebar />
        <Container className="flex-grow-1">
          <Row className="justify-content-center align-items-center h-100">
            <Col md={8} lg={6}>
              <div className="bg-light p-4 shadow-sm">
                <h2 className="text-center">Welcome to the Dashboard</h2>
                <p className="text-center">
                  This is where you can manage your settings and view information.
                </p>
                <div className="text-center mt-4">
                  <Button variant="danger" onClick={handleLogout}>
                    Logout
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </main>
  );
}

export default Dashboard;
