import React from 'react';
import './App.css';
import Header from './Header';
import LoggedInHeader from './LoggedInHeader';
import Footer from './Footer';
import { Button, Container, Form, Nav, Navbar } from 'react-bootstrap'; // Simplified imports
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'; // Import Link for routing

import Login from './Login';
import Register from './Register';
import Dashboard from './pages/Dashboard';

const isLoggedIn = localStorage.getItem('token');

function App() {
  return (
    <div className="App">
      {/* BrowserRouter at the top level */}
      <Router>
      {/* {isLoggedIn ? <LoggedInHeader /> : <Header />} */}
      <Header />
        {/* Routes */}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>

        <Footer  />
      </Router>
    </div>
  );
}

export default App;
